@import '../../assets/scss/colors';
@import '../../assets/scss/mixins';
@import '../../assets/scss/extended-classes';
@import '../../assets/scss/break-points';

// Project meetings tab content sections
.space-meetings-page {
  padding: 3.2rem 0 0;
  @include bp(smd-max) {
    margin-bottom: 2.4rem;
  }
  .tab-content-wrapper {
    .MuiBox-root {
      border: 0 none;
      .MuiTabs-root {
        .MuiTabs-fixed {
          .MuiTabs-flexContainer {
            width: fit-content;
            background: $grey13;
            border-radius: 0.8rem;
            .MuiTab-root {
              padding: 6px 12px;
              color: $black1;
              text-transform: none;
              letter-spacing: 0.014rem;
              @include line-height(14, 20);
              font-weight: 400;
              &:hover {
                color: $purple1;
              }
              &.Mui-selected {
                background: $lightPurple;
                color: $purple1;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                border-radius: 0.8rem;
                letter-spacing: 0.014rem;
              }
            }
          }
          .MuiTabs-indicator {
            background: none;
            height: 0;
          }
        }
      }
    }
    // Tab content sections
    .MuiTabPanel-root {
      padding: 2rem 0 0 0;
    }
    .tab-content-section {
      .tab-content-row {
        display: flex;
        align-items: flex-end;
        padding: 1.6rem 1.6rem 2rem;
        border: 1px solid $grey2;
        border-radius: 0 0 0 2.4rem;
        background: $white;
        margin-bottom: 1.6rem;
        position: relative;
        @include bp(sm-max) {
          padding-top: 2rem;
        }

        .col1 {
          width: 44%;
          @include bp(xlg-max) {
            width: 52%;
          }
          .badge {
            background-color: $grey4;
            color: $black1;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            @include line-height(10, 16);
            letter-spacing: 0.1rem;
            text-transform: uppercase;
            padding: 0rem 0.8rem 0rem;
            border-radius: 2rem;
            height: 2rem;
            width: max-content;
            border: 0.1rem solid $grey25;
            display: flex;
            align-items: center;
            &.green {
              border: 0;
              color: $white;
            }
            &.past-badge {
              color: $grey4;
              border: 0.1rem solid $grey2;
            }
          }
          .title {
            color: $black1;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 500;
            @include line-height(16, 24);
            display: flex;
            align-items: center;
            margin-bottom: 1.5rem;
            flex-wrap: wrap;
          }
          .scanner {
            background-color: $lightPurple;
            font-size: 1.2rem;
            font-weight: 500;
            border-radius: 2rem;
            margin-left: 1rem;
            padding: 0.2rem 0.8rem;
            display: flex;
            align-items: center;
            em {
              display: flex;
              width: 1.2rem;
              margin-right: 0.3rem;
            }
          }
        }
        .col2 {
          width: 28%;
          display: flex;
          @include bp(xlg-max) {
            width: 24%;
          }
          .info-title {
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 500;
            color: $black1;
            display: flex;
            align-items: center;
            &:first-child {
              margin-right: 1.6rem;
            }
            .avatar,
            .initials {
              @include user-avatar(
                1.2rem,
                500,
                $black1,
                $purple9,
                2.4rem,
                2.4rem,
                2.4rem
              );
              border-radius: 1.6rem;
              border: 1px solid $white;
              margin: 0 0 0 0.4rem;
              cursor: default;
              &.overlapped {
                margin-right: -1rem;
              }
            }
            .initials {
              background-color: $grey2;
              cursor: pointer;
            }
          }
        }
        .col3 {
          width: 28%;
          justify-content: flex-end;
          display: flex;
          position: relative;
          top: 0.8rem;
          @include bp(xlg-max) {
            width: 24%;
          }
          .MuiButtonBase-root {
            padding: 1.6rem 2rem;
            margin-left: 1rem;
            // background-color: $white;
            // border-radius: 8px;
            // border: 1px solid rgba(179, 136, 255, 0.30);
            @include bp(xl-max) {
              padding: 1.6rem 1rem;
            }
            &.btn-download {
              width: max-content;
            }
            .icon {
              margin-right: 0.8rem;
            }
            &.is-icon-btn {
              padding: 0;
              width: 3.6rem;
              img {
                width: 1.6rem;
                height: 1.6rem;
              }
            }
            &.download-btn {
              color: $black1;
              &:hover {
                border: 1px solid $purple2;
              }
            }
            &.download-btn {
              background-color: $white;
              border-radius: 8px;
              border: 1px solid rgba(179, 136, 255, 0.3);
              padding-right: 1.5rem;
              em {
                display: flex;
                font-size: 1.1rem;
                margin-left: 1rem;
                &:before {
                  font-weight: 600 !important;
                }
              }
            }
          }
          .icon-buttons-wrap {
            display: flex;
            align-items: center;
            .down-arrow {
              margin-left: 1rem;
              .be-down-arrow {
                display: flex;
                font-size: 1rem;
                &::before {
                  font-weight: 600 !important;
                }
              }
            }
            .MuiButton-root {
              background-color: $white;
            }
          }
        }
        @include bp(lg-max) {
          flex-flow: column;
          .col1 {
            display: flex;
            text-align: center;
            flex-flow: column;
            .badge {
              margin: auto;
            }
          }
        }
        .col1,
        .col2,
        .col3 {
          @include bp(lg-max) {
            width: 100%;
            margin: 0 0 1.5rem;
            order: -1;
            justify-content: center;
          }
        }
        .delivery-tag {
          background-color: $grey11;
          font-size: 1.2rem;
          color: $white;
          font-weight: 500;
          border-radius: 0 0 0 0.8rem;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          position: absolute;
          right: 0;
          padding: 0.4rem 1.5rem;
          top: 0;
          @include bp(sm-max) {
            font-size: 1rem;
            padding: 0.3rem 1rem;
          }
        }
      }
    }
    .meeting-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include bp(sm-max) {
        flex-wrap: wrap;
      }
      .sort-by {
        font-size: 1.4rem;
        color: $black1;

        .dropdown-text {
          font-weight: 400;
          margin-right: 0.3rem;
        }
        .MuiSelect-select {
          font-weight: 500 !important;
          padding-right: 2.5rem !important;
        }
      }
    }
  }
}

.MuiTooltip-popper {
  .tooltip-content {
    &.multiple-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      min-width: 13rem;
      > span {
        border-bottom: 1px solid $lightPurple;
        padding: 0.8rem 0;
        width: 100%;
        text-align: center;
        &:last-child {
          border: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

// Tab's content scroll
.space-meeting-section-scroll {
  @include bp(xl-max) {
    max-height: calc(100vh - 22.2rem) !important;
  }
  // @include bp(smd-max) {
  //   max-height: calc(100vh - 27rem) !important;
  // }
}

.tab-content-section {
  .meeting-card-section {
    display: none;
    margin-top: -1.5rem;
    .meeting-card {
      border-bottom: 0.1rem solid $grey2;
      padding: 1.6rem 1.6rem 2.4rem;
      h3 {
        font-size: 1.4rem;
        font-weight: 500;
        color: $black1;
        margin-bottom: 1rem;
      }
      .agenda-block {
        ul {
          margin: 0;
          padding: 0 0 0 2.4rem;
          li {
            font-size: 1.4rem;
            color: $black1;
            margin-top: 0.5rem;
            &::marker {
              font-size: 1rem;
            }
          }
        }
      }
      .meeting-links {
        font-size: 1.4rem;
        .link-container {
          display: flex;
          align-items: center;

          .transparent-btn {
            background-color: rgba(0, 0, 0, 0);
            border: none;
            height: 2rem;
            &.copy-btn {
              background-color: $white;
              border: 0.1rem solid rgba(179, 136, 255, 0.3);
              width: 3rem;
              height: 3rem;
              min-width: 3rem;
              padding: 0;
              border-radius: 0.5rem;
              margin-left: 1rem;
              &:hover {
                border-color: $purple2;
              }
              em {
                display: flex;
              }
            }
          }

          a {
            font-size: 1.4rem;
            color: $purple1;
            text-decoration: none;
            display: flex;
            align-items: center;
            word-break: break-word;
            em {
              display: flex;
              align-items: center;
              margin-left: 1rem;
            }
          }
        }
      }

      .participants-block {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        @include bp(sm-max) {
          flex-wrap: wrap;
          margin-bottom: 0.5rem;
        }
        li {
          background-color: $white;
          border: 0.1rem solid $grey6;
          border-radius: 0.8rem;
          font-size: 1.2rem;
          color: $black1;
          display: flex;
          align-items: center;
          padding: 0.8rem 1.6rem;
          margin-right: 0.8rem;
          letter-spacing: 0.1rem;
          .avatar,
          .initials {
            @include user-avatar(
              1.2rem,
              500,
              $black1,
              $purple9,
              2.4rem,
              2.4rem,
              2.4rem
            );
            border-radius: 1.6rem;
            border: 1px solid $white;
            margin: 0 0.5rem 0 0;
            font-style: normal;
            cursor: default;
          }
        }
      }
      .attachement-item {
        background-color: $white;
        border: 0.1rem solid $grey2;
        padding: 0.8rem 1.2rem 0.8rem 0.8rem;
        border-radius: 0.8rem;
        display: inline-flex;
        align-items: center;
        margin-right: 1rem;
        margin-bottom: 1rem;
        .attach-icon {
          background-color: $lightPurple;
          width: 3.2rem;
          height: 3.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.8rem;
        }
        .attachment-middle {
          margin: 0 1rem;
          span {
            display: block;
            &.file-name {
              font-size: 1.2rem;
              font-weight: 400;
              color: $black1;
            }
            &.file-size {
              font-size: 1rem;
              font-weight: 400;
              color: $grey11;
            }
          }
        }
        .be-vertical-dots {
          font-size: 2rem;
          font-weight: 500;
          color: $black1;
          cursor: pointer;
          display: flex;
        }
      }
      .count {
        background-color: $grey4;
        border-radius: 8rem;
        padding: 0.5;
        font-size: 1rem;
        font-weight: 500;
        color: $white;
        width: 2rem;
        height: 2rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 2rem;
        margin: 0rem 0 0 0.6rem;
        position: relative;
        top: -0.2rem;
      }
    }
  }
  .active {
    border-radius: 0 0 0 2.4rem;
    border: 0.1rem solid $purple2;
    margin-bottom: 1.5rem;
    .meeting-card {
      &:last-child {
        border-bottom: 0;
      }
    }
    .tab-content-row {
      border-radius: 0 !important;
    }
    .meeting-card-section {
      display: block;
    }
    .download-arrow {
      transform: rotate(180deg);
    }
  }
}

.download-paper {
  em {
    margin-left: 1rem;
    font-size: 1.6rem;
    display: flex;
  }
  .MuiPaper-rounded {
    width: 20.6rem !important;
    ul {
      li {
        font-size: 1.4rem;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
      }
    }
  }
}
.sidenav-visible {
  .download-btn {
    @include bp(xll-max) {
      font-size: 1.1rem !important;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
}
