$black: #000000;
$white: #ffffff;
$purple: #651fff;
$purple1: #6200ea;
$purple2: #b388ff;
$purple3: #7c4dff;
$purple10: #946dff;
$purple4: #6200ea;
$purple5: #bda5ff;
$purple6: #eeeeee;
$purple7: #ebebeb;
$purple8: #536dfe;
$blue2: #3d5afe;
$purple9: #d9c4ff;
$purple11: #F3EBFF;
$bcoin:#651FFF;
$lightPurple: #f3ebff;
$lightPurple1: #fbf8ff;
$trueGrey: #595959;
$grey: #c0c3ce;
$grey1: #fafbfc;
$grey2: #dfe0e6;
$grey3: #e8e8e8;
$grey4: #676b7e;
$grey5: #9b9fb1;
$grey6: #ecedf0;
$grey7: #f8f9fa;
$grey8: #d0d2da;
$grey9: #8c8c8c;
$grey10: #d9d9d9;
$grey11: #83889e;
$grey12: #afb2c0;
$grey13: #f5f5f5;
$grey14: #ecedf0;
$grey15: #f7f5ff;
$grey16: #bcb1f5;
$grey17: #f6f1fe;
$grey18: #cdcfd8;
$grey19: #e8dbff;
$grey20: #fcfbfd;
$grey21: #9a9ba1;
$grey22: #f0eafd;
$grey23: #fcfcfd;
$grey24: #e8dcff;
$grey25: #d9c4ff;
$grey26:#676b7e;
$grey27: #3c3e49;
$green: #00e676;
$green1: #00c853;
$green2: #e2fcea;
$black1: #3c3e49;
$black2: #262626;
$dark-grey: #484848;
$red: #d50000;
$red1: #ff8a80;
$light-red: #fee5e2;
$dark-red: #f7221f;
$red3: #ff5546;
$amber: #ffab00;
$yellow: #ffe57f;
$yellow2: #ffd740;
$pink: #f588ff;
$green2: #b3d380;
$org: #ffb186;
$orange2: #fff9e1;
$orange3: #ffc9c9;
$dgreen: #008243;
$lgreen: #ebf5f0;
$lred: #ffecec;
